import React from 'react'

import styles from "./../../policies-css-module.module.scss";
import '../../../styles.scss';

import { Layout } from '../../../components';
import  BigDiscountDaysOffer   from '../../../components/policies/bigDiscountDaysOffer/big-discount-days-offer';

import { Link } from "gatsby"

const BigDiscountDays = () => {
	return (
		<>
			<Layout>
					<div className={styles.container}>
						<div className={styles.link_section}>
							<div className={styles.link_section_div}>
								<Link exact className={styles.tc_links} activeClassName='is-active' to='/bigdiscountdays/tnc/'>
									BIG DISCOUNT DAYS OFFER 
								</Link>
							</div>
						</div>
						<div className={styles.content_section}>
								<BigDiscountDaysOffer />
						</div>
					</div>
			</Layout>
		</>
	)
}

export default BigDiscountDays
